import React, { useState, useRef } from "react";
import { Formik, Form } from "formik";
import jsonp from "jsonp";

import Box from "@material-ui/core/Box";
import Fade from "@material-ui/core/Fade";
import Typography from "@material-ui/core/Typography";

import TextField from "Main/components/TextField";

const EMAIL_REGEX = /^\S+@\S+\.\S+$/;

/**
 * Step prompts for user email and forwards it to mailchimp
 */
const Email = ({ options, setActiveStep, soundcloudPlayer }) => {
  const [placeholder, setPlaceholder] = useState();
  const inputEl = useRef(null);

  const handleSubmit = (values, { setSubmitting, setFieldValue }) => {
    if (EMAIL_REGEX.test(values.email)) {
      sessionStorage.setItem("email", values.email);
      const email = encodeURIComponent(values.email);

      jsonp(
        `https://nickdorian.us17.list-manage.com/subscribe/post-json?u=006c98868c69cbe6106692ff3&id=5a8975ae56&EMAIL=${email}`,
        { param: "c" }
      );

      soundcloudPlayer.play();
      setSubmitting(false);
      const { next } = options[0];
      setActiveStep(next);
    } else {
      setPlaceholder("Enter a valid email");
      setFieldValue("email", "");
      setTimeout(() => {
        setPlaceholder("");
        setSubmitting(false);
        inputEl.current.focus();
      }, 3000);
    }
  };

  return (
    <Fade in timeout={3000}>
      <Box position="sticky" top="50%" textAlign={{ xs: "left", sm: "center" }}>
        <Typography>Enter your email to play</Typography>
        <Formik onSubmit={handleSubmit} initialValues={{ email: "" }}>
          <Form>
            <TextField
              name="email"
              autoComplete="off"
              containerProps={{ ml: { sm: 4 } }}
              inputRef={inputEl}
              placeholder={placeholder}
            />
            <input style={{ display: "none" }} type="submit" />
          </Form>
        </Formik>
      </Box>
    </Fade>
  );
};
export default Email;
