import { send, init } from "emailjs-com";
import { EMAILJS_ID, EMAIL_TEMPLATE_ID, EMAIL_SERVICE_ID, REPLY_TO_EMAIL } from "config";

init(EMAILJS_ID);

const sendEmail = async ({ email, ending }) => {
  try {
    await send(
      EMAIL_SERVICE_ID,
      EMAIL_TEMPLATE_ID,
      { from_email: email, ending, reply_to: REPLY_TO_EMAIL },
      EMAILJS_ID
    );
  } catch {
    console.error(`There was a problem sending this email.`);
  }
};

export default sendEmail;
