import { createMuiTheme } from "@material-ui/core";

const htmlFontSize = 16;
const fontSize = 14;
export const pxToRem = (size) => `${(size / htmlFontSize) * (fontSize / 14)}rem`;

const fontWeightLight = 300;
const fontWeightRegular = 400;
const fontWeightMedium = 500;
export const fontWeightSemiBold = 600;
const fontWeightBold = 700;

const theme = createMuiTheme({
  palette: {
    background: {
      default: "#272C2B",
    },
    text: {
      primary: "#0da493",
    },
  },
  typography: {
    fontFamily: `"JoystixMonospace-Regular", sans-serif`,
    htmlFontSize,
    fontSize,
    fontWeightLight,
    fontWeightRegular,
    fontWeightMedium,
    fontWeightSemiBold,
    fontWeightBold,
    body1: {
      color: "#0da493",
      letterSpacing: pxToRem(1),
      fontSize: pxToRem(30),
      filter: "drop-shadow(1.294px 4.83px 0px rgba(51,158,147,0.59))",
    },
  },
});

theme.typography.body1 = {
  ...theme.typography.body1,
  [theme.breakpoints.down("md")]: {
    fontSize: pxToRem(26),
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: pxToRem(22),
  },
};

export default theme;
