import Step0a from "images/map.gif";
import Step1 from "images/step-1-island.gif";
import Step2 from "images/step-2-footsteps.gif";
import Step2a from "images/step-2a-sandcastle.gif";
import Step3 from "images/step-3-rain.gif";
import Step3a from "images/step-3a-umbrella.gif";
import Step4 from "images/step-4-book.gif";
import Step4a from "images/step-4a-feet.gif";
import Step5 from "images/step-5-note.gif";
import Step6 from "images/step-6-anything.gif";
import Step7 from "images/step-7-face.gif";
import Step8 from "images/charliefrog.gif";

export const values = {
  0: {
    type: "view",
    text: "Sound on",
    options: [{ next: "0a" }],
  },
  "0a": {
    type: "email",
    text: "Email",
    options: [{ next: "0b" }],
  },
  "0b": {
    type: "view",
    image: Step0a,
    options: [{ next: "1" }],
    timeout: 7200,
    verticalImage: true,
  },
  1: {
    type: "input",
    text: "You are on a boat and you see an island, where do you go?",
    image: Step1,
    options: [
      { triggers: ["island", "shore"], next: "2" },
      {
        triggers: ["boat"],
        responses: ["But you’ve been here your whole life"],
      },
      { responses: ["You can do that later", "Have faith in the design"] },
    ],
  },
  2: {
    type: "input",
    text: "You’re on the shore by yourself. You look down and see footsteps. What do you do?",
    image: Step2,
    options: [
      {
        triggers: ["castle", "sandcastle", "bucket", "shovel", "shore"],
        next: "2a",
      },
      { triggers: ["follow", "footsteps"], next: "3" },
      { responses: ["Haven’t you done that before?"] },
    ],
  },
  "2a": {
    type: "input",
    text: "You stay on the shore and build a castle. It’s getting dark. Is it time to go to sleep?",
    image: Step2a,
    options: [{ triggers: ["ye"], next: "2aa" }, { next: "2ab" }],
  },
  "2aa": {
    type: "view",
    text: "Maybe it’s all just a dream",
    options: [{ next: "1" }],
  },
  "2ab": {
    type: "view",
    text: "Maybe you should go to sleep",
    options: [{ next: "2" }],
  },
  3: {
    type: "input",
    text: "After walking a few hours, it begins to rain. What do you do?",
    image: Step3,
    options: [
      { triggers: ["umbrella", "shelter"], next: "3a" },
      { triggers: ["footsteps", "follow", "walking", "following", "going"], next: "4" },
      { responses: ["I wouldn't do that again", "Keep going"] },
    ],
  },
  "3a": {
    type: "view",
    text:
      "You stay under the umbrella, but the rain doesn’t seem to be ending any time soon. The consensus is to go back to the boat.",
    image: Step3a,
    options: [{ next: "1" }],
    timeout: 7200,
  },
  4: {
    type: "input",
    text: "The rain stops. On the side of the trail you see a book. Do you read the book?",
    image: Step4,
    options: [
      { triggers: ["no"], next: "4a" },
      { triggers: ["ye"], next: "5" },
      {
        responses: [
          "I really would like to get away from here as well. It would be a lovely feeling.",
          "Keep going",
        ],
      },
    ],
  },
  "4a": {
    type: "input",
    text:
      "You continue walking but realize the rain has washed away the footprints. You best go back to the shore. Would you like for me to bring you back around?",
    image: Step4a,
    options: [
      { triggers: ["ye"], next: "2" },
      {
        responses: [
          "The least I can do is leave you alone",
          "What a familiar response, you’ve said that before",
        ],
      },
    ],
  },
  5: {
    type: "input",
    text: "You pick up the book and start reading. The story is unfinished. Do you finish it?",
    image: Step5,
    options: [
      { triggers: ["ye"], next: "5a" },
      {
        triggers: ["no"],
        responses: [
          "Just like the story this journey has no ending, so you must go back to the beginning",
        ],
      },
      { responses: ["You can do that later"] },
    ],
  },
  "5a": {
    type: "input",
    text: "How does your story end? Press enter when you are finished writing.",
    image: Step5,
    options: [{ next: "6" }],
    saveResponse: true,
  },
  6: {
    type: "input",
    text:
      "Your story has been written, so you follow the footsteps until you are at the heart of the island. A familiar face appears. Do you stop to talk?",
    image: Step6,
    options: [
      { triggers: ["ye"], next: "7" },
      { triggers: ["no"], responses: ["But you’ve made it this far"] },
      { responses: ["Ay ya ya"] },
    ],
  },
  7: {
    type: "input",
    text: "The familiar face asks you, “What can you do?” What is your reply?",
    image: Step7,
    options: [
      {
        triggers: ["i can do anything", "icandoanything", "anything"],
        exact: true,
        next: "8",
      },
      { responses: ["Try again", "there’s a million possible things"] },
    ],
  },
  8: {
    type: "view",
    image: Step8,
  },
};
