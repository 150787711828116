import React from "react";
import { useField, useFormikContext } from "formik";

import Box from "@material-ui/core/Box";
import MuiTextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  placeholder: {
    position: "absolute",
    top: "0.15em",
  },
}));

/**
 * A wrapper around the MUI TextField that adds Formik
 * support using hooks (useField, useFormikContext).
 */
const TextField = ({ containerProps, placeholder, ...props }) => {
  const classes = useStyles();

  const [field] = useField(props);
  const { isSubmitting } = useFormikContext();

  return (
    <Box display="flex" alignItems="center" {...containerProps}>
      <Typography>&gt;&gt;</Typography>
      <Box position="relative" ml={0.5} flex="1 1 auto">
        <MuiTextField
          autoFocus
          fullWidth
          autoComplete="off"
          disabled={isSubmitting}
          InputProps={{ disableUnderline: true }}
          {...field}
          {...props}
        />
        <Typography className={classes.placeholder}>{placeholder}</Typography>
      </Box>
    </Box>
  );
};

export default TextField;
