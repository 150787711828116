import React, { useEffect } from "react";

import Box from "@material-ui/core/Box";
import Fade from "@material-ui/core/Fade";
import Typography from "@material-ui/core/Typography";

/**
 * Step renders text (and optional image) & then automatically updates activeStep after 3 seconds
 */
const View = ({ text, image, verticalImage, options, setActiveStep, timeout = 6000 }) => {
  useEffect(() => {
    if (options) {
      const { next } = options[0];
      setTimeout(() => {
        setActiveStep(next);
      }, timeout);
    } else {
      // if last step, redirect to daphne major souncloud link
      setTimeout(() => {
        window.location.href =
          "https://soundcloud.com/nickdorian/sets/daphne-major-masters/s-pcqAXilg5lQ?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing";
      }, timeout);
    }
  }, [options, setActiveStep, timeout]);

  return (
    <Fade in timeout={3000} key={image}>
      <div>
        {image && (
          <img
            src={image}
            alt=""
            width="100%"
            style={{ maxWidth: verticalImage ? 600 : "none'" }}
            height={text ? "auto" : "100%"}
          />
        )}
        <Box pt={image ? 4 : "25%"} textAlign={image ? "left" : "center"}>
          <Typography>{text}</Typography>
        </Box>
      </div>
    </Fade>
  );
};

export default View;
