import React, { useState, useRef, useEffect } from "react";
import loadscript from "load-script";

import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";

import { Email, Input, View } from "./Step";
import { values } from "./values";

const useStyles = makeStyles((theme) => ({
  root: ({ type, text }) => ({
    height: "100%",
    paddingTop: theme.spacing(5),
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(5, 15),
    },
    overflow: "hidden",
    maxWidth: type === "input" || (type === "view" && text) ? 1000 : "none",
  }),
}));

const Main = () => {
  const [activeStep, setActiveStep] = useState("0");
  const [soundcloudPlayer, setSoundcloudPlayer] = useState(false);
  const { type, text } = values[activeStep];
  const soundcloudRef = useRef(null);

  const classes = useStyles({ type, text });

  const Component = type === "email" ? Email : type === "view" ? View : Input;

  useEffect(() => {
    // use load-script module to load SC Widget API
    loadscript("https://w.soundcloud.com/player/api.js", () => {
      const player = window.SC.Widget(soundcloudRef.current);
      setSoundcloudPlayer(player);

      // play track again when it finishes.
      const { FINISH } = window.SC.Widget.Events;
      player.bind(FINISH, () => {
        player.play();
      });
    });
  }, []);

  return (
    <Container className={classes.root}>
      <Component
        setActiveStep={setActiveStep}
        soundcloudPlayer={soundcloudPlayer}
        ref={type === "email" ? soundcloudRef : null}
        {...values[activeStep]}
      />
      <iframe
        title="icandoanythingtrack"
        width="0"
        height="0"
        scrolling="no"
        frameBorder="no"
        allow="autoplay"
        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/933241273%3Fsecret_token%3Ds-uEThl3b8GS4&amp;color=%23ff5500&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false&amp;show_teaser=true&amp;visual=true"
        ref={soundcloudRef}
      />
    </Container>
  );
};

export default Main;
