import React, { useState, useRef } from "react";
import { Formik, Form } from "formik";

import Box from "@material-ui/core/Box";
import Fade from "@material-ui/core/Fade";
import Typography from "@material-ui/core/Typography";

import TextField from "Main/components/TextField";
import sendEmail from "utils/sendEmail";

/**
 * Step renders TextField and either prints response or updates activeStep based on user input
 */
const Input = ({ text, image, options, setActiveStep, saveResponse }) => {
  const [placeholder, setPlaceholder] = useState("");
  const inputEl = useRef(null);

  const handleSubmit = ({ input }, { resetForm, setSubmitting }) => {
    // clear input but do not set isSubmitting to false until after state has been updated
    resetForm({ isSubmitting: true });

    // default to last (catch-all) element in options array
    let [{ next, responses }] = options.slice(-1);

    // loop through options & break if input contains any of the substrings in the triggers array
    for (let i = 0; i < options.length; i++) {
      const { triggers, exact } = options[i];
      if (
        triggers &&
        triggers.some((str) =>
          exact ? input.toLowerCase() === str : input.toLowerCase().includes(str)
        )
      ) {
        ({ next, responses } = options[i]);
        break;
      }
    }

    // email management email with response if we're saving it
    if (saveResponse) {
      const email = sessionStorage.getItem("email");
      sendEmail({ email, ending: input });
    }

    // update placeholder to be a random response from the responses array & clear after 3 seconds
    if (responses) {
      const response = responses[Math.floor(Math.random() * responses.length)];
      setPlaceholder(response);
      setTimeout(() => {
        setPlaceholder("");
        setSubmitting(false);
        inputEl.current.focus();
      }, 3000);
    }

    if (next) {
      setSubmitting(false);
      inputEl.current.focus();
      setActiveStep(next);
    }
  };

  return (
    <Fade in timeout={3000} key={image}>
      <div>
        <img src={image} alt="" width="100%" />
        <Box pt={4} textAlign="left">
          <Typography>{text}</Typography>
          <Formik onSubmit={handleSubmit} initialValues={{ input: "" }}>
            <Form>
              <TextField
                name="input"
                containerProps={{ ml: { sm: 4 } }}
                inputRef={inputEl}
                placeholder={placeholder}
              />
            </Form>
          </Formik>
        </Box>
      </div>
    </Fade>
  );
};

export default Input;
